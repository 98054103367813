// ALIGN FLEX CELLS - can't figure out default Foundation way.

.flex-align-left {
  display: flex;
  justify-content: flex-start;
}

.flex-align-right {
  display: flex;
  justify-content: flex-end;
}

.rounded {
  &,
  &.callout {
    border-radius: $global-radius;
  }
  
}

// RESPONSIVE TYPOGRAPHY MIXIN

@mixin fluid-type(
  $min-font-size: 12px,
  $max-font-size: 21px,
  $lower-range: 420px,
  $upper-range: 1200px
) {
  font-size: calc(
    #{$min-font-size} + #{(
        ($max-font-size / ($max-font-size * 0 + 1)) -
          ($min-font-size / ($min-font-size * 0 + 1))
      )} * ((100vw - #{$lower-range}) / #{(
            ($upper-range / ($upper-range * 0 + 1)) -
              ($lower-range / ($lower-range * 0 + 1))
          )})
  );
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range) {
    font-size: $max-font-size;
  }
}


// EQUAL HEIGHT FOUNDATION GRID XY

.equal-height-cards {
  .cell {
    display: flex;
    align-items: stretch;
  }
}


// VERTICAL CENTER HEADER ON IMAGE
// place header above image and add class ".verticalcenter"

.verticalcenter {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  z-index: 1;
}


// DISPLAY CLASSES

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}




// LIMIT CLASSES ============================================

.limit {
  margin: 0 auto;

  @include breakpoint(medium) {

    &--10 {
      margin: 0 auto;
      max-width: 83.33333%;
    }
  
    &--8 {
      margin: 0 auto;
      max-width: 66.66666%;
    }
  
    &--6 {
      margin: 0 auto;
      max-width: 50%;
    }
  
    &--4 {
      margin: 0 auto;
      max-width: 33.33333%;
    }

  }

}


// BREAkOUT CLASSES ================================

.breakout {

  @include breakpoint(medium) {
    
    &--2 {
      width: 132.6% !important;
      margin-left: -16.3%;
      margin-right: -16.3%;
    }
  }

}




// GRID ============================================

.flowgrid {
  .elementor-widget-wrap {
    display: flex;
    flex-flow: row wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    width: auto;

    .elementor-element {
      width: 100%;
      margin-right: 1rem;
      margin-left: 1rem;

      @include breakpoint(medium) {
        width: calc(50% - 2rem);
      }

      @include breakpoint(large) {
        width: calc(33.33333% - 2rem);
      }
    }
  }
}


// EQUAL HEIGHT ELEMENTOR COLUMNS

.elementor {
  .equalheight {
    .elementor-column-wrap {
      display: flex;

      .elementor-widget-wrap {
        display: flex;
      }
    }
  }
}