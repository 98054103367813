.wpcf7-form {

 font-size: 14px;

 input,
 textarea,
 select {
  border: 0;
  background: #dedede;
  border-radius: 0;
  box-shadow: none;
  resize: none;
  font-size: 14px;
  color: #bbb;

  &::placeholder {
    color: #999;
  }

  &:focus {
   background: transparent;
   border: 1px solid #F1F1F1;
   box-shadow: none;
  }
 }

}

.wpcf7-submit {
    background: $orange;
    color: white;
}

select {
 border: 0;
 background-color: #F1F1F1;
 border-radius: 0;
 resize: none;
 font-size: 14px;

 &:focus {
  background-color: transparent;
  border: 1px solid #F1F1F1;
  box-shadow: none;
 }

 .site-footer & {
  background-color: #fff;
  background-image: url(../images/pointer/pointer-down-orange.svg);
  background-repeat: no-repeat;
  background-position: right -12px top 8px;
  background-size: 15px 10px;
  font-size: 14px;
 }

}