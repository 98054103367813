.rating {

    // RESET SOME STYLES

    ul.pods-dfv-list,
    .pods-dfv-field .pods-pick-values > ul {
        background: transparent;
        margin: 0 0 5px 0;
        padding: 0;
        border-radius: 0;
        border: none;
        overflow: visible;
        max-height: none;
        overflow-y: auto;
    }
    
    .pods-pick-values li,
    .pods-pick-values li:nth-child(even),
    .pods-pick-values li:hover {
        background-color: transparent;
        border-bottom: none;
        line-height: 1em;
        margin: 0;
        padding: 0;
    }

    // RATING STARS
  
    .pods-radio {
      position: relative;
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-flex;
      z-index: 0;
          
      .pods-field {
        position: static;
        display: block;
        height: 2rem;
        width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        
        label:before {
          background: white url(https://webchimp.nl/stars.svg) no-repeat 0 0;
          content: '';
          background-size: 160px auto;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          position: absolute;
          z-index: -11;
                    
        }
        
        input {
          display: none;
        }
        
        label {
          overflow: hidden;
          text-indent: -99px;
          width: 2rem;
          height: 2rem;
          cursor: pointer;
          
          &:after {
            content: "";
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
          }
        }
      }
  
      // first star checked
      li:nth-child(1) .pods-field input:checked + label {
        &:before {
          background-position: 0 -32px;
          z-index: -10;
        }
      }
      
      // second star checked
      > li:nth-child(2) .pods-field input:checked + label {
        &:before {
          background-position: 0 -64px;
          z-index: -9;
        }
      }
     
      // third star checked
      > li:nth-child(3) .pods-field input:checked + label {
        &:before {
          background-position: 0 -96px;
          z-index: -8;
        }
      }
      
      // fourth star checked
      > li:nth-child(4) .pods-field input:checked + label {
        &:before {
          background-position: 0 -128px;
          z-index: -7;
        }
      }
      
      // fifth star checked
      > li:nth-child(5) .pods-field input:checked + label {
        &:before {
          background-position: 0 -160px;
          z-index: -6;
        }
      }
      
      // first star hover
      > li:nth-child(1) .pods-field label:hover {
        &:before {
          background-position: 0 -32px;
          z-index: -5;
        }
      }
  
      // second star hover
      > li:nth-child(2) .pods-field label:hover {
        &:before {
          background-position: 0 -64px;
          z-index: -4;
        }
      }
      
      // third star hover    
      > li:nth-child(3) .pods-field label:hover {
        &:before {
          background-position: 0 -96px;
          z-index: -3;
        }
      }
      
      // fourth star hover        
      > li:nth-child(4) .pods-field label:hover {
        &:before {
          background-position: 0 -128px;
          z-index: -2;
        }
      }
      
      // fifth star hover            
      > li:nth-child(5) .pods-field label:hover {
        &:before {
          background-position: 0 -160px;
          z-index: -1;
        }
      }
    }
  }