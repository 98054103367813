.callout {
    border: 0;
  
    &--shadow {
      background: white;
      box-shadow: 0 0 24px 0 rgba(0,0,0,.2);
      padding: 1rem;
      border-radius: $global-radius;
    }
  
  }