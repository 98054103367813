// SECTIONS ==========================

.section {
    padding: 1rem;
  
    @include breakpoint(medium) {
      padding: 2rem 1rem;
    }
  
    @include breakpoint(large) {
      padding: 3rem 1rem;
    }
  
    &--flush {
        padding: 0!important;
    }

    &--widgets {
      color: white;
      padding: 2rem 1rem;
  
      @include breakpoint(medium) {
        padding: 3rem 1rem;
      }
  
      @include breakpoint(large) {
        padding: 4rem 1rem;
      }
    }
  
    &--breadcrumb {
      // hide breadcrumb on home
      padding: 1rem 0 0 0;

      body.home & {
        display: none !important;
      }
    }

    &--footer {
      
      .grid-container {
        position: relative;
      }

    }
   
    &--topbar {
      padding: .5rem;
      width: 100%;
      z-index: 100;
      top: 0;
      transition: 0.4s;
      text-align: right;
      position: relative;
      background:  white;
      height: 52px;
      box-shadow: 0 0 48px 0 rgba(0,0,0,.1);
  
      a {
        color: white;
      }
  
      .off-canvas-content.is-open-top & {
        background: none;
        .topbar__phone {
          opacity: 0;
        }
      }
    }
  
    &--mainnav {
      padding: 0;
      position: relative;
      z-index: 100;
  
      @include breakpoint(small only) {
        display: none;
      }
    }

    &--main {
      padding: 0 1rem 0 1rem;
      position: relative;
      z-index: 100;
  
      @include breakpoint(medium) {
        padding: 0 2rem 0 2rem;
      }
    }


    &--credits {
      padding: 1rem 1rem 0.8rem 1rem;
      text-align: center;
    }
  
    &--navbar {
      padding: 1rem 0.5rem 1rem 0.5rem;
      margin-bottom: 1rem;
      
      @include breakpoint(medium) {
        margin-bottom: 2rem;
      }

      @include breakpoint(large) {
        margin-bottom: 4rem;
      }
    }
      
    &--pagination {
      background: $black;
      background-size: cover;
      color: white;
      padding: 2rem;
  
      .grid-container {
        margin-bottom: 2rem;
      }
  
      a {
        color: white;
      }
  
      img {
        border: 6px solid white;
      }
  
      .media-object-section:first-child {
        padding-right: 0;
        margin-right: 1rem;
      }
    }

    &--color1 {
        background: $black;
    }

    &--color2 {
        background: $black;
    }
    
    &--color3 {
        background: $black;
    }
    
  }