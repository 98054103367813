// WEBCHIMP CREDITS ====================================

.webchimp-credit {
  position: absolute;
  z-index: 10;
  right: 1rem;
  bottom: 1rem;
  display: block;
  background: url(../images/webchimp-heart.png) no-repeat center top;
  font-size: 0;
  background-size: 30px auto;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  transition: 0.3s;
  opacity: 0.4;
  color: white;

  &::after {
    content: "";
    background: url(../images/webchimp-icon-transparent.svg) no-repeat center center;
    background-size: cover;
    height: 0px;
    width: 0px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -2;
    transition: 0.4s;
    display: block;
    transform-origin: center bottom;
  }

  &::before {
    content: attr(data-title);
    background: #88c426;
    color: white;
    border-radius: 4px;
    position: absolute;
    right: 40px;
    top: 2px;
    max-width: 0px;
    overflow: hidden;
    display: block;
    transition: 0.4s;
    transition-delay: 0.4s;
    z-index: -2;
    font-size: 12px;
    white-space: nowrap;
    padding: 4px 0;
  }

  &:hover {
    background-position: center bottom;
    opacity: 1;

    &::before {
      max-width: 1000px;
      padding: 4px 16px;
    }

    &::after {
      top: -25px;
      animation: wave 2s linear infinite alternate;
    }
  }
}
