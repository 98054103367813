// SITE FOOTER =========================

.site-footer {
    background: url(../images/footer-background.svg) no-repeat center top;
    background-size: cover;
    padding-top: 5rem;
    margin-top: 6rem;
  
    .idTag {
      position: absolute;
      top: -12.1rem;
      left: 0;
      width: 212px;
      height: auto;
      margin: 0;
    }
  
    .footer-widgets {
      font-size: 15px;
  
      @include breakpoint(small only) {
        h4 {
          margin-top: 2rem;
        }
      }
  
      @include breakpoint(large) {
        text-align: left;
      }
  
      h4 {
        font-size: 16px;
        margin-bottom: 1rem;
        text-transform: uppercase;
      }
  
      a {
        color: rgba(254, 254, 254, 0.8);
        text-decoration: none;
        padding: 0;
        display: block;
        line-height: 24px;
        transition: 0.4s;
  
        &:hover {
          color: white;
        }
      }
  
      ul.menu {
        flex-direction: column;
  
        a:before {
          content: "» ";
        }
      }
    }
  
    .footer-credits {
      font-size: 14px;
      color: $white;
  
      a {
        color: $white;
        border-bottom: 1px dotted white;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }