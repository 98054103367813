.scrollhide-nav {
    position: fixed;
    z-index: 999;
    right: 0;
    top: 0;
    left: 0;
    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
  
    .admin-bar & {
      top: 32px;
    }
  
    &.hidden {
      transform: translateY(-200%);
    }
  }