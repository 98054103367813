/* elementor accordion */

.elementor-element {
    // ACCORDION ( elementor vertical accordion )
  
    .elementor-accordion {
      color: $black;
  
      .elementor-accordion-item {
        margin-bottom: 1rem;
        background: white;
        box-shadow: 0 0 12px 0 rgba(0,0,0,.2);
  
        .elementor-tab-title {
          &.elementor-active {
            background: white;
          }
        }
  
        .elementor-tab-content {
          background: white;
  
          .elementor-active {
            background: white;
          }
        }
      }
    }
  }
  