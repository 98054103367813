.callout {
    padding: 0;
    border: 0;
    border-radius: 0;
  
    figcaption {
      padding: 1rem;
    }
  
    &--form {
      padding: 3rem;
    }
  
    &--white {
      background: white;
      box-shadow: 0 0 24px 0 rgba(0,0,0,.2);  
      padding: 2rem;
      z-index: 1;
    }

    &--shadow {
      background: white;
      box-shadow: 0 0 24px 0 rgba(0,0,0,.2);
      z-index: 1;
    }
  
    &--opening {
      border: 1px solid white;
    }
  }
  

  .pull-left-2 {
    
    @include breakpoint(medium) {
      margin-left: -4rem;
      margin-top: 2rem
    }

  }