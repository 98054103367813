html body .elementor-widget-wrap .elementor-widget.pricelist ul.elementor-price-list {
    margin: 2rem 0;
    
    li {
        border-bottom: 1px solid lighten($orange, 30%);
        margin-bottom: .5rem;

        .elementor-price-list-item {

            .elementor-price-list-header {
                font-weight: normal;
                @include fluid-type(14px, 16px);
                margin-bottom: .5rem;

                .elementor-price-list-title {
                    
                    font-weight: normal;
                }
                .elementor-price-list-separator {
                    display: none;
                }
                .elementor-price-list-price {
                    
                }
            }
        }

        &:last-child {
            border-bottom: 0;
        }
    }
}

