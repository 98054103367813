// CUSTOM LISTS ========================

/*ul*/
.vink {
    padding: 0;
    margin: 0;
    list-style: none;
  
    li {
      padding: 0 0 0 20px;
      background: white
        url("data:image/svg+xml,%0A%3Csvg width='14px' height='13px' viewBox='0 0 14 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 49 %2851002%29 - http://www.bohemiancoding.com/sketch --%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='homepage' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Large-Device-Desktops' transform='translate%28-208.000000, -728.000000%29' fill='%231BAF39'%3E%3Cpath d='M214.591826,741.051758 L221.75115,728.875488 L220.088222,728 L214.199353,737.708435 L209.373475,732.211682 L208,733.651817 L214.591826,741.051758 Z' id='Rectangle-46'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
        no-repeat left top 7px;
      margin-bottom: 15px;
    }
  }


//  CUSTOM BULLETS ELEMENTOR IMPLEMENTATION ==============================

.uspbox .elementor-widget-container ul {
  @extend .vink;
}
  