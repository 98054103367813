
// 404 PAGINA
.page404 {
    text-align: center;
    padding-top: 2rem;
  
    &__bottom {
      background: white;
      color: $black;
      padding: 0 2rem 2rem 2rem;
  
      @include breakpoint(large) {
        padding: 4rem;
      }
    }
  }