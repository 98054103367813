// ELEMENTOR BTN CLASSES ==========================================================

.btn {
    .elementor-button.elementor-button-link {
      background: $orange;
      color: #fff;
      padding: .5rem 1.5rem;
      transition: .5s;

      &:hover {
        background: darken($orange, 10%);
      }
    }

    &--text {
      .elementor-button.elementor-button-link {
        background: transparent;
        color: $black;
      }
    }


    &--cta {
      .elementor-button.elementor-button-link {
        @include breakpoint(small only) {
          margin: 0 auto;
          min-width: 220px;
          max-width: 220px;
          font-weight: 400;
          font-size: 120%;
          padding: 1rem 1.5rem;
        }
      }
    }
    
    &--ghost {
      .elementor-button.elementor-button-link {
        background: transparent;
        border: 2px solid $black;
        color: $black;
  
        // &:after {
        //   content: url("data:image/svg+xml,%0A%3Csvg width='18px' height='13px' viewBox='0 0 18 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 49 %2851002%29 - http://www.bohemiancoding.com/sketch --%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ghost-button' transform='translate%28-163.000000, -18.000000%29' fill='%236B6864' fill-rule='nonzero'%3E%3Cpath d='M174.628178,18.1826071 C174.392927,17.939131 174.001391,17.939131 173.757915,18.1826071 C173.522665,18.4178576 173.522665,18.8093936 173.757915,19.0440957 L178.170645,23.456826 L163.609239,23.456826 C163.269798,23.4573744 163,23.7271723 163,24.066613 C163,24.4060538 163.269798,24.6846256 163.609239,24.6846256 L178.170645,24.6846256 L173.757915,29.0891303 C173.522665,29.3326064 173.522665,29.7246907 173.757915,29.9593929 C174.001391,30.202869 174.393475,30.202869 174.628178,29.9593929 L180.085552,24.5020185 C180.329028,24.266768 180.329028,23.875232 180.085552,23.6405299 L174.628178,18.1826071 Z' id='Shape-Copy-2'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        //   position: relative;
        //   top: 2px;
        //   left: 4px;
        //   transition: 0.3s;
        // }
  
        // &:hover {
        //   &:after {
        //     left: 6px;
        //   }
        // }
      }
    }
  
    &--diap {
      .elementor-button.elementor-button-link {
        background: #fff;
        border: none;
        color: $black;
        box-shadow: 0 0 24px 0 lighten($black, 60%);
    
        &:hover {
          box-shadow: 0 0 6px 0 lighten($black, 60%);
        }
      }
    }


  
  }