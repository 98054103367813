@charset 'utf-8';

@import "settings";
@import "foundation";
@import "motion-ui";

//webchimp eigen components vanuit webchimp-base

@include foundation-global-styles;
//@include foundation-grid;
//@include foundation-flex-grid;

@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
//@include foundation-slider;
@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import "webchimp/utilities";

// Global
@import "global/_accessibility";
@import "global/colors";
@import "global/wp-admin";
@import "global/wp-overrides";
@import "global/typography";

// Modules
@import "modules/navigation";
@import "modules/content";
@import "modules/footer";
@import "modules/editor-style";

// Elementor
@import "elementor/elementor-column-fix";
@import "elementor/elementor-buttons";
@import "elementor/elementor-image";
@import "elementor/elementor-accordion";
@import "elementor/elementor-imagebox";
@import "elementor/elementor-alert";
@import "elementor/elementor-pricelist";

// Components
@import "components/cards";
@import "components/featured-image";
@import "components/dividers";
@import "components/links";
@import "components/social";
@import "components/lists";
@import "components/pills";
@import "components/paragraphs";
@import "components/slicks/slicknav";

// Foundation
@import "foundation/buttons";
@import "foundation/callout";

// CUSTOM REVEAL ANIMATION
@import "animation/reveal";
@import "animation/hover-animation";

// WEBCHIMP
@import "webchimp/reset";
@import "webchimp/webchimp";
@import "webchimp/lists";
@import "webchimp/credits";
@import "webchimp/photoframes";
@import "webchimp/sections";
@import "webchimp/callout";
@import "webchimp/hero";
@import "webchimp/forms";
@import "webchimp/404";
@import "webchimp/scrollhidenav";
@import "webchimp/ankeiler";
@import "webchimp/breadcrumb";
@import "webchimp/footer";
@import "webchimp/offcanvas";
//@import "webchimp/mainnav";
@import "webchimp/idtag";
@import "webchimp/menutoggle";

// animation with zoom and double curtaints
@import "webchimp/entryanimations/curtainanimation";
@import "webchimp/sliders/franklinslider";
@import "webchimp/pods/pods-fields";
@import "webchimp/sliders/kenburnslider";

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

nav.mainnav > .menu {
  flex-wrap: nowrap;
}

body {
  &:before {
    content: '';
    background: url(../images/nav-background.svg) no-repeat left bottom;
    position: absolute;
    z-index: -2;
    height: 224px;
    right: 0;
    left: calc((50% - (#{$global-width}/2)) + 18rem);

    @include breakpoint(small only) {
      content:  none;
    }

    @include breakpoint(medium only) {
      left: calc((20% - (#{$global-width}/2)) + 12rem);
      background: none;
    }

    @include breakpoint(large) {
      left: calc((50% - (#{$global-width}/2)) + 12rem);
    }

    @include breakpoint(1440px) {
      left: calc((50% - (#{$global-width}/2)) + 12rem);
    }

    .home & {
      height: 370px;
    }
  }
}


.site-header {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;

  @include breakpoint(large) {
    position: relative;
  }
}



.homeslider {
  @include breakpoint(large) {
    //margin-left: -1rem;
  }
}






//
.shape-2-orange {
  &:before {
    content: '';
    background: url(../images/logo-shape-3.svg) no-repeat right top;
    background-size: cover;
    position: absolute;
    z-index: -2;
    top: -20vh;
    left: 0;
    width: calc((50vw - (#{$global-width}/2)) + 4rem);
    height: 100vh;
  }
}


// extra classe om centreren + links, rechts alignen mogelijk te maken. (Zie Hallux)

.usplist {

  .elementor-column {

    .elementor-text-editor {
      text-align: center;

      @include breakpoint(medium) {
        text-align: right;

        li:after {
          margin: .5rem 0 .5rem auto;
        }
      }
    }

    &:last-child .elementor-text-editor {

      @include breakpoint(medium) {
        text-align: left;

        li:after {
          margin: .5rem auto .5rem 0;
        }
      }
    }

  }

}



.swoosh-grey {
  position: relative;
  z-index: 0;

  &:before {
    content: url(../images/swoosh-grey.svg);
    position: absolute;
    margin: 0 auto;
    min-width: 100vw;
    right: 0;
    z-index: -1;
  }
}


.swoosh-orange {
  position: relative;
  z-index: 0;

  &:before {
    content: url(../images/swoosh-orange.svg);
    position: absolute;
    margin: 0 auto;
    right: 0;
    z-index: -1;
  }
}





// <div class="cell  medium-6">
// 	<a href="{@permalink}">
// 		<div class="teamlid">
// 			<figure class="teamlid__image">
// 				<img src="{@teamlid_afbeelding._src.index-afbeelding}" alt="{@teamlid_naam}" />
// 			</figure>
// 			<div class="teamlid__content">
// 				<h3 class="teamlid__title">{@teamlid_naam}</h3>
// 				<p class="teamlid__function">{@teamlid_functie}</p>
// 			</div>
// 		</div>
// 	</a>		
// </div>


.teamlid {
  background: white;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.2);
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  overflow: hidden;
  border-radius: 12px;

  &__image {
    flex: 0 1 auto;
    width: 50%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 1 auto;
    width: 50%;
    padding: .5rem;
    text-align: left;
    align-items: center;
    

    p {
      margin: 0;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: $orange;
    text-align: center;
  }

  &__function {
    color: $grey;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

}




// <div class="media-object teampanel">
// 	<div class="media-object-section">
// 		<figure class="teampanel__image">
// 			{@teamlid_afbeelding._img.medium}
// 		</figure>
// 	</div>
// 	<div class="media-object-section">
// 		<h4 class="teampanel__name">{@teamlid_naam}</h4>
// 		<div class="teampanel__function">{@teamlid_functie}</div>
// 		<div class="teampanel__bio">{@teamlid_biografie}</div>
// 		<div class="teampanel__contact">{@teamlid_adresgegevens}</div>
// 	</div>
// </div>

.small_submit input{
  color: $orange;
  font-weight: 500;
  padding: 0;
  border: 0;
  background: transparent;
}

.teampanel {
  margin-bottom: 1rem;

  @include breakpoint(medium) {
    margin-bottom: 2rem;
  }
 
  &__image { 
    
  }

  &__name {
    font-size: 24px;
    color: $orange;
    font-weight: 600;
    margin: 0;
  }

  &__function {
    font-size: 24px;
    color: $grey;
    font-weight: 600;
  }

  &__bio {
    
  }

  &__contact {
    color: $orange;
    a {
      color: $orange;
    }
  }
}

.is-dropdown-submenu-parent a::after {
  top: unset;
}

.mainnav {

  .menu {

    a {
      color: white;
    }

    &.submenu {
      font-size: 85%;
      box-shadow: 0 0 24px 0 rgba(0,0,0,.25);
      
      .menu-item {
        border-bottom: 1px solid grey;
        
        &:last-child {
          border-bottom: 0;
        }
      }

      a {
        color: $black;
      }
      
    }
  }

}

.search-button {
  background: white;
  border-radius: 4px;
  padding: 1rem;
  position: relative;
  right: 0;
  top: -3rem;
}



//// ELEMENTOR OVERRIDES

html {

  .elementor-slick-slider .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
  
}

.credits {
  display: inline;
  div {
    display: inline;
  }
}

.position-top {
  height: auto;
  padding: 1rem;
}

.search-result {
  a {
    color: $orange;
  }

  h2 {
    @include fluid-type(18px, 20px);
    margin: 1rem 0 0 0 !important;
  }
}


.elementor-text-editor h1,
.elementor-text-editor h2,
.elementor-text-editor h3,
.elementor-text-editor h4 {
  color: #5AAC92;
}

.h1 {
  color: #5AAC92;
}

.elementor-text-editor img {
  margin-bottom: 1rem;
}
.site-footer .grid-padding-x {
  margin-bottom: 2rem;
}


.site-footer .footer_widget_3 {
  padding-left: 2rem;
}


.elementor-cta {
  overflow: hidden;
  border-radius: 12px;
}

.swiper-slide {
  border-radius: 12px;
}