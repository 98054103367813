// FOUNDATION BUTTON CLASSES ===========================================

.button {
    font-weight: bold;
    transition: 0.3s;
    padding: .5rem 1.5rem;
    text-decoration: none;
    line-height: 1rem;
    background: $grey;
  
    &--orange {
      color: white!important;
      background: $orange;
  
      &:hover {
        background: darken($orange, 5%);
      }
    }
  
    &--dark {
      color: white;
      background: $black;
  
      &:hover {
        opacity: 0.8;
      }
    }
  
    &--diap {
      border: 1px solid white;
      border-radius: 3px;
      background: #fff;
      color: $black;
      margin: 0;
  
      &:hover {
        border: 1px solid white;
        color: white;
        background: transparent;
      }
    }
  }