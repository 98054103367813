// brand colors

$black: #3A3A3A;
$grey: #5E5E5C;
$orange: #EE662F;
$green: #5AAC92;
$white: #ffffff;

// pencils

.black {
  color: $black;
}

.grey {
  color: $grey;
}

.white {
  color: $white;
}

.orange {
  color: $orange;
}

// #E68018 > #
