/* SLICK NAV */

.homeslider .elementor-slick-slider .slick-arrow {
 position: absolute;
 display: block;
 width: 100px;
 height: 48px;
 bottom: 1rem;
 text-indent: -999px;
 overflow: hidden;
 cursor: pointer;
 border-radius: 0;
 transform: none;
 top: unset;

 &.slick-prev {
  background: white url(../images/pointer/arrow-left-grey.svg) no-repeat center center;
  border-radius: 0;
  width: 48px;
  height: 48px;
  left: 1rem;
 }

 &.slick-next {
  background: white url(../images/pointer/arrow-right-grey.svg) no-repeat center center;
  border-radius: 0;
  width: 48px;
  height: 48px;
  left: 76px;
 }

}



/* SLICK DOTS */

.elementor .elementor-slick-slider {

    ul.slick-dots {
        position: absolute;
        bottom: 1rem;
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        width: 100%;
        margin: 0;
       
        li {
         position: relative;
         display: inline-block;
         height: 14px;
         width: 14px;
         margin: 0 .25rem;
         padding: 0;
         cursor: pointer;
         background: white;
       
         button {
          border: 0;
          background: $black;
          opacity: 0;
          display: block;
          height: 15px;
          width: 15px;
          outline: none;
          line-height: 0;
          font-size: 0;
          color: transparent;
          padding: 5px;
          cursor: pointer;       
         }
       
         &.slick-active {
          background: $orange;
         }
         
        }
       
        .slick-slider--diap & {
         li button {
          background: white;
         }
        }
       }

}

