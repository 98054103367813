// TAGS ==========================

.pills {
    text-align: center;
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 80%;
      max-width: 680px;
      margin: 0 auto;
  
      li {
        display: inline-block;
        padding: 0.5rem 1rem 0.7rem 1rem;
        background: black;
        color: white;
        border-radius: 6px;
        margin: 0 0.5rem 1rem 0.5rem;
        line-height: 20px;
      }
    }
  }


  // .pills ul li a 