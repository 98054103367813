// ELEMENTOR MEDIA-BOX STYLING

.elementor-container {
    .mediabox {
      border: 1px solid red;
      color: $black;
  
      figure {
        text-align: center;
        margin-bottom: 0;
  
        img {
          max-width: 58px;
          margin-bottom: 0;
        }
      }
  
      .elementor-image-box-title {
        color: $black;
        font-size: 20px;
        margin: 0;
      }
  
      &--cta {
        .elementor-image-box-title {
          color: white;
        }
  
        figure {
          img {
            min-width: 100%;
          }
        }
      }
    }
  }

