/*
* SOCIAL ICONS
* 
* 
*
* 
*/



.social {
    display: flex;
    justify-content: center;
    list-style: none;
  
    > li {
      padding: 0 .5rem;
  
      img {
        width: 24px;
        height: 24px;
        position: relative;
        transition: .4s;
        top: 0;
  
        &:hover {
          top: 3px;
        }
      }
    }
  }