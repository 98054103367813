// HERO STYLES ==============================

.hero {
    position: relative;
    overflow: hidden;
    background: $black;
    text-align: center;
    height: 200px;
    width: auto;
  
    @include breakpoint(medium) {
      height: 300px;
    }
    @include breakpoint(large) {
      height: 400px;
    }
    @include breakpoint(xlarge) {
      height: 500px;
    }
    @include breakpoint(xxlarge) {
      height: 600px;
    }
  
    img {
      position: relative;
      height: 100%;
      width: auto;
      max-width: none;
      top: 50%;
      transform: translateY(-50%);
    }
  
    div {
      position: absolute;
      left: -10px;
      right: -10px;
      top: -10px;
      bottom: -10px;
      filter: blur(120px);
      background-size: cover;
    }
  }