// ID-TAG ==========================

.idTag {
    z-index: 100;
    margin: 0 auto;
    position: absolute;
    width: auto;
    height: 36px;
    left: 0;
    right: 0;
    top: 8px;
  
    @include breakpoint(large) {
      position: relative;
      height: auto;
      width: 212px;
    }

    &--mobile {
      border-radius: 50%;
      border: 20px solid white;
      width: 126px;
      height: 106px;
      top: -2rem;
      background: white;
      text-align: center;
      padding-top: 18px;

      img {
        width: auto;
        height: 48px;
      }
    }
  }