// entry animation TADA override slider

html body div[data-settings*='tada'] {
    animation: none !important;
    visibility: visible;
    
    .elementor-image {
      position: relative;
      z-index: 0;
      overflow: hidden;
      visibility: visible;
  
      img {
        transform-origin: center center;
        transform: scale(1.3);
        transition: all 2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        opacity: .5;
      }
  
      &:after,
      &:before {
        transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        z-index: 10;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #336699;
        opacity: .6;
        visibility: visible!important;
      }
  
      &:before {
        
      }
    }
  
    &.tada {
  
      .elementor-image {
  
        img {
          transform: scale(1);
          opacity: 1;
        }
        
        &:before {
          right: 100%;
          opacity: 1;
        }
  
        &:after {
          transition: all 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          right: 100%;
          opacity: 1;
          transition-delay: .3s;
        }
      }
    }
  }