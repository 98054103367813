// OFFCANVAS STYLES ===============================

.off-canvas {
  color: white;
  background: $black;
  background-size: 122px auto;
  text-align: left;
  padding: 2rem 0;

  .menu.vertical {
    width: 100%;
    margin-bottom: 2rem;
  }

  a {
    color: white;
    font-size: 18px;
    line-height: 48px;
    font-weight: 500;
  }

  .menu {
    
    a {
      color: white;
      font-size: 24px;
      line-height: 48px;
      padding: 0 2rem;
    }

    .current-menu-item a {
      color: $orange;
    }

    > li .menu {
      padding: 0;
      margin: 0;

      &.submenu {
        padding-bottom: 1rem;
        
        a {
          font-size: 18px;
          line-height: 36px;  
        }
      }
  
    }

    
    li[aria-expanded="true"] {
      background: rgba(254,254,254,.2);
    }
  }

  // logo
  img {
    margin: 0 auto;
    display: block;
    width: 84px;
    height: auto;
    margin-bottom: 2rem;
  }
  
}

//prevent scrolling on body if mobile nav is active
.nav-active body {
  overflow: hidden;
}


// some padding if mobile nav is fixed
.off-canvas-content {
  padding-top: 6rem;

  @include breakpoint(large) {
    padding-top: 0rem;
  }
  
}