// animated underline

.animated-line {

  > .menu > li > a {
    position: relative;
    padding: 0.2rem 0;
    margin: 0 1rem;
    white-space: nowrap;

    &:after {
      content: '';
      height: 2px;
      position: absolute;
      left: 50%;
      right: 50%;
      bottom: 0;
      transition: .3s;
      background: white;
    }

    &:hover::after {
      left: 0;
      right: 0;
    }
  }

  > ul > li.current-menu-item a:after {
    left: 0;
    right: 0;
  }

}



