// PARAGRAPHS

// SHIFTED IMAGES ON PARAGRAPH

.paragraph--shift  {

    .elementor-widget-image img {
        transition: 1s;
        transition-delay: .5s;
    }

    .elementor-row .elementor-column:first-child  {
        padding-right: 2rem;

        @include breakpoint(medium) {
            padding-right: 3rem;
        }

        @include breakpoint(large) {
            padding-right: 4rem;
        }
        
    }

}

.slick-current  {

    .elementor-image img {
        transform: translate(-2rem, 2rem);
    
        @include breakpoint(medium) {
            transform: translate(-3rem, 3rem);
        }

        @include breakpoint(medium) {
            transform: translate(-4rem, 4rem);
        }
        
    }
}

