/*
* SLIDER FOR FRANKLIN SCHIEMAND
* add '.slider-franklink' class to elemnentor section
* initiate .slider-franklink js
*
*/

.franklin-slider {
    // set some static

    margin-bottom: 12rem;
  
    .elementor-container,
    .elementor-row,
    .elementor-column,
    .elementor-column-wrap,
    .slick-slider {
      position: static!important;
    }
  
    .slick-list {
      overflow: hidden;
      padding-top: 8rem !important;
      padding-bottom: 8rem !important;
      position: static !important;
      cursor: url(../images/horizontal-slider.svg);
  
      .slick-slide {
        opacity: .5;
        transition: .5s;
        filter: grayscale(100%);
      }
  
      .slick-current {
        opacity: 1;
        transform-origin: center center;
        transform: scale(2);
        position: relative;
        z-index: 100;
        filter: none;
      }
    }
  
    .elementor-column-wrap {}
    position: static!important;
  
    .slick-arrow {
      position: absolute;
      width: 30px;
      height: 70px;
      top: 50%;
      transform: translateY(-50%);
      text-indent: -999px;
      opacity: .5;
      overflow: hidden;
      cursor: pointer;
      
      &.slick-prev {
        left: 2rem;
        background: transparent url(../images/slick-arrow-left.svg) no-repeat center center;
      }
  
      &.slick-next {
        right: 2rem;
        background: transparent url(../images/slick-arrow-right.svg) no-repeat center center;
      }
  
    }
  }
  

