// styling lists by adding a class to the text widget
// .list
// .stacked
// .custom

.list {
    // stacked as in homepage hallux
    &--stacked {
        ul {
            list-style-type: none;
            margin: 0;

            li  {
                position: relative;

                &:after {
                    display: block;
                    content: "";
                    height: 2px;
                    width: 140px;
                    background: #DCDCDC;
                    margin: .5rem auto;
                }
                &:last-child::after {
                    content: unset;
                }
            }
        }
    }

    // custom bullets
    &--custom {
        ul {
            list-style: url(../images/custom-bullet.svg);
            li {
                margin-bottom: .5rem;
            }
        }
    }

    &--boxed {
        
        ul {
            background: #FDF3E9;
            list-style: url(../images/custom-bullet.svg);
            padding: 1.5rem;
            margin: 0;

            > li {
                margin-bottom: .5rem;
                margin-left: 1rem;
            }

            @include breakpoint(medium) {
                padding: 2rem;
            }

        }
    }

    &--blocked {

        ul {
            display: flex;
            flex-wrap:  wrap;
            margin: 0 -1rem;
            justify-content: center;
        }
        
        ul li {
            background: $orange;
            color: white;
            flex: none;
            margin: 1rem;
            
            display: flex;
            justify-content: center;
            align-items: center;
            word-wrap: break-word;
            text-align: center;
            width: calc(50% - 2rem);

            @include breakpoint(medium) {
                width: calc(33.2% - 2rem);
            }
            
            @include breakpoint(medium) {
                width: calc(20% - 2rem);
            }

            div {
                padding: 1rem;
            }
        }
    }
}




// <ul class="link-list">
//     <li><a href="#">Podotherapie</a></li>
//     <li><a href="#">Orthopedische schoenen</a></li>
//     <li><a href="#">Fits slipper en sandalen</a></li>
//     <li><a href="#">Veiligheidsschoenen</a></li>
//     <li><a href="#">Reuma en podotherapie</a></li>
//     <li><a href="#">Diabetes podotherapie</a></li>
//     <li><a href="#">Sporters</a></li>
//     <li><a href="#">Kinderen</a></li>
//     <li><a href="#">Ouderen</a></li>
//     <li><a href="#">Algemene voetzorg</a></li>
// </ul>


//List with links 
//submenu etc.

.link-list {
    list-style: none;
    padding: 0;
    margin: 0;
  
    > li {
      border-bottom: 1px solid lighten($grey,55%);
  
      a {
        display: flex;
        justify-content: space-between;
        padding: .5rem .5rem .5rem 0;
        opacity: .5;
        
        &:after {
          content: url(../images/pointer/pointer-right-grey.svg);
            
        }
  
        &:hover {
          opacity: 1;
        }
      }
      &:last-child {
        border: 0;
      }
    }
  }