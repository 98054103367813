// elementor column fix

.elementor-container  {

    &.elementor-column-gap-default {
  
      > .elementor-row {
        margin-left: -10px;
        margin-right: -10px;
        width: calc(100% + 20px);
      }
  
    }
  
    &.elementor-column-gap-narrow {
  
      > .elementor-row {
        margin-left: -5px;
        margin-right: -5px;
        width: calc(100% + 10px);
      }
  
    }
  
    &.elementor-column-gap-extended {
  
      > .elementor-row {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
      }
  
    }
  
    &.elementor-column-gap-wide {
  
      > .elementor-row {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
      }
  
    }
  
    &.elementor-column-gap-wider {
  
      > .elementor-row {
        margin-left: -30px;
        margin-right: -30px;
        width: calc(100% + 60px);
      }
  
    }
  
  }