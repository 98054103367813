// ELEMENTOR IMAGES =========================

.elementor-image {
    figure {
      img {
        width: 100%;
        height: auto;
      }
      figcaption {
        text-align: left;
        background: #eee;
        padding: 0.5rem 1rem;
      }
    }
  }