// <div class="elementor-element elementor-element-1edb52a ankeiler elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action" data-id="1edb52a" data-element_type="widget" data-widget_type="call-to-action.default">
//   <div class="elementor-widget-container">
//     <div class="elementor-cta">
//         <div class="elementor-cta__content">
//             <div class="elementor-content-item elementor-cta__content-item elementor-cta__image">
//       <img width="394" height="436" src="http://dev.hallux.nl/wp-content/uploads/sites/40/2019/05/home-2.jpg" class="attachment-full size-full" alt="Home" srcset="http://dev.hallux.nl/wp-content/uploads/sites/40/2019/05/home-2.jpg 394w, http://dev.hallux.nl/wp-content/uploads/sites/40/2019/05/home-2-271x300.jpg 271w" sizes="(max-width: 394px) 100vw, 394px">					</div>

//             <h2 class="elementor-cta__title elementor-cta__content-item elementor-content-item">
//       Pijnwijzer voet, been, heup of onderrug					</h2>


//             <div class="elementor-cta__button-wrapper elementor-cta__content-item elementor-content-item ">
//     <a class="elementor-cta__button elementor-button elementor-size-sm" href="http://dev.hallux.nl/klachtenwijzer/">
//       Overzicht klachten					</a>
//     </div>
//         </div>
//       </div>
//   </div>
// </div>
//
// An ankeiler is a teaser. Particulary useful to create some attentions to a subject on the homepage.


.elementor-cta--skin-classic.ankeiler {

 margin-bottom: 2rem !important;

 .elementor-cta__content {
  padding: 0;
  position: relative;

  &:after,
  &:before {
   z-index: 1;
   content: '';
   position: absolute;
   left: 0;
   right: 0;
   height: 45%;
  }

  &:before {
   top: 0;
   background-image: linear-gradient(0, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .3) 100%);
  }

  &:after {
   bottom: 0;
   background-image: linear-gradient(0, rgba(0, 0, 0, .3) 0, rgba(0, 0, 0, 0) 100%);
  }

  .elementor-content-item {
   margin: 0;

   img {
    max-width: none;
    width: 100%;
   }
  }

  .elementor-cta__title {
   color: white;
   text-align: left;
   position: absolute;
   width: auto;
   top: 1rem;
   left: 1rem;
   right: 1rem;
   z-index: 2;
   @include fluid-type(20px, 32px);
   line-height: 1.2;

   @include breakpoint(large) {
    top: 2rem;
    left: 2rem;
    right: 2rem;
   }
  }

  .elementor-cta__button {
   z-index: 2;
   border: 0;
   background: white;
   position: absolute;
   left: 1rem;
   bottom: 1rem;
   border-radius: 24px;

   @include breakpoint(large) {
    bottom: 2rem;
    left: 2rem;
   }

   &:after {
     content: url(../images/pointer/pointer-right-orange.svg);
     margin-left: .5rem;
     top: 3px;
     position: relative;
   }

  }

 }
}