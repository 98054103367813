.elementor {

  .elementor-alert {

    &.elementor-alert-info {
      border: 0;
      background: $orange;
      color: #fff;
      border-radius: 4px;
      font-size: 16px;
    }
  
  }

}
