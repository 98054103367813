/**
 * TYPOGRAPHY =========================================================
 * .page-title
 * .section-title
 *
 *
*/

// fonts
@import url('https://fonts.googleapis.com/css?family=Asap:400,400i,500,500i,700');


body {
    color: $black;
    @include fluid-type(14px, 16px);
}

// ELEMENTOR PAGE TITLE

.page-title {

    &,
    &.elementor-heading-title {
        font-weight: 500;
        font-size: 26px;
        @include fluid-type(26px, 36px);
    }

}

// ELEMENTOR SECTION TITLE
.section-title {

    &,
    &.elementor-heading-title {
        font-weight: 500;
        text-align: center;
        @include fluid-type(28px, 36px);
    }

}

// INTRO TEXT

.intro {
    @include fluid-type(16px, 20px);
    font-weight: 500;
    margin: 0 auto;
}

.elementor-widget-heading {
    font-weight: 500
}

.section .h1 {
    &,
    &.elementor-heading-title {
        @include fluid-type(36px, 64px);
        font-weight: 500;
        line-height: 1.2;
    }
}

.section .h2 {

    &,
    &.elementor-heading-title {
        @include fluid-type(20px, 40px);
        line-height: 1.2;        
    }
}

.section .h3 {

    &,
    &.elementor-heading-title {
        @include fluid-type(19px, 31px);
        margin-bottom: 2rem;
        line-height: 1.2;        
    }
}

.section .h4 {

    &,
    &.elementor-heading-title {
        @include fluid-type(18px, 25x);
    }
}

.section .h5 {

    &,
    &.elementor-heading-title {
        @include fluid-type(17px, 20px);
    }
}

.section .h6 {

    &,
    &.elementor-heading-title {
        @include fluid-type(16px, 16px);
    }
}



h1 {
    @include fluid-type(24px, 48px);
}

h2 {
    @include fluid-type(20px, 40px);
}

h3 {
    @include fluid-type(19px, 31px);
}

h4 {
    @include fluid-type(18px, 25px);
}

h5 {
    @include fluid-type(17px, 20px);
}

h6 {
    @include fluid-type(16px, 16px);
}

.elementor-widget-heading {
    
    h1.elementor-heading-title {
        margin-bottom: 1rem;
    }
   
    h2.elementor-heading-title {
        margin-bottom: 1rem;
    }
   
    h3.elementor-heading-title {
        margin-bottom: 1rem;
    }


}


.elementor-text-editor a {
    color: $orange;
}