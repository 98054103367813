// idTag
.idTag {
    span {
      display: none;
    }
}

  // section
.section {
  padding: 1rem;

  &--flush {
    padding: 0;
  }
}




