/*
* SLIDER WITH KENBURN EFFECT
* add '.kenburnslider' class to component
* initiate kenburnslider js
*
* todo: fix first transition
*/

.kenburnslider {

  .slick-slide-inner {
    @extend .custom-mask;
    height: 500px;

    .slick-slide-image {
      height: 100%;
      width: 100%;
      object-fit: cover !important;
      object-position: 50% 20%;
      transition: 12s;
      transform: scale(1);
    }

    @include breakpoint(medium) {
      height: 600px;
    }

    @include breakpoint(large) {
      height: 700px;
    }

    @include breakpoint(xlarge) {
      height: 900px;
    }

    @include breakpoint(xxlarge) {
      height: 1000px;
    }
  }

}


.active .kenburnslider .slick-active .slick-slide-image {
  transform: scale(1.1);
}


.custom-mask {
  clip-path: polygon(0 99%, 25% 90%, 25% 99%, 75% 90%, 75% 99%, 100% 90%, 100% 99%, 100% 0, 0 0);
}
